@font-face {
    font-family: MontserratBold;
    src: url('../Fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  }
@font-face {
    font-family: MontserratMedium;
    // src: url('../Fonts/Avenir/Avenir\ Next\ W10\ Medium.ttf') format('truetype');
    src: url('../Fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  }
@font-face {
    font-family: MontserratRegular;
    src: url('../Fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  }
  
@font-face {
    font-family: GeezaRegular;
    src: url('../Fonts/GeezaPro/Geeza-Pro-Regular.ttf') format('truetype');
  }

@font-face {
    font-family: Times;
    src: url('../Fonts/Times/timr45w.ttf') format('truetype');
  }

@font-face {
    font-family: TimesRoman;
    src: url('../Fonts/Times\ Roman/OPTITimes-Roman.otf');
}

// body {
//   font-family: GeezaRegular;
// }

// h1,h2,h3,h4 {
//   font-family: GeezaRegular;
// }