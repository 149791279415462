footer {
  
  .follow-section {

    background-color: $mainColor;
    color: #d8d8d8;
    padding: 30px 0;

    span {
      font-family: 'MontserratBold';
      font-size: $lg-size;
    }

    .social-links {
      margin-inline-start: 5px;
      a {
          color: #fff;
          padding: 0 7px;
      }
    }
  }

  .footer-section {

    background-color: #1f1f1f;
    color: #fff;
    max-height: 338px;

    @media #{$minXLarge} {
      padding: 80px 0 30px 0;
    }

    @media #{$minLarge} {
      padding: 60px 0 20px 0;
    }

    @media #{$maxMed} {
      padding: 30px 0;
    }

    @media #{$maxMobile} {
      padding: 10px 0 20px 0;
    }
    

    .f-row {

      height: 140px;

      @media #{$maxSmall} {
        height: 100px;
      }

      div {
        text-align: center;
      }

      div:not(:first-of-type) {
        line-height: 135px;
      }

      div:not(:first-of-type), div:not(:first-of-type) img {
        max-height: 100%;
      }

      div {
        @media #{$maxMed} {
          align-items: center;
          display: flex;
        }
      }

    }

    .footer-links {

      border-bottom: 1px solid #484848;


      .links-section {
        display: flex;
        justify-content: flex-end !important;
        justify-content: center;
        flex-wrap: wrap;

        @media #{$minLarge} {
          padding: 40px 0 0 0;
        }

        @media #{$maxMed} {
          justify-content: center !important;
          margin: 0;
        }

      }

      .QL {
        display: flex;
        align-items: flex-end;
        @media #{$maxMed} {
          justify-content: center;
        }
      }

      a, span {
        padding: 15px;
        color: #fff;

        @media #{$maxLarge} {
          font-size: 13px
        }
        
        @media #{$maxMed} {
          padding: 12px;
        }

        @media #{$maxSmall} {
          font-size: 11px;
          padding: 10px;
        }

        @media #{$maxMobile} {
          font-size: 10px;
          padding: 6px 8px 10px;
        }

      }

      span {
        padding-inline-start: 0;
        
        @media #{$minSmall} and #{$maxMed} {
          margin-top: 20px;
        }
      }

    }

  }

}
