.form-group {
  gap: 10px;
  align-items: baseline;
}
.form-control {
  height: auto !important;
  border: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: start;
  &.flex-column {
    align-items: flex-start !important;
  }
  label.form-label {
    font-size: 17px;
    font-weight: bold;
    text-align: start;
    color: #374750;
  }
  input,
  textarea,
  select {
    width: max-content;
    height: 60px;
    margin: 10px 0px;
    padding: 18px 14px;
    border-radius: 7px;
    border: solid 1px #979797;
    flex-grow: 2;
  }
  select,
  span.p-calendar {
    width: 100%;
  }
  &.page-title,
  &.about-content {
    label.form-label {
      flex-basis: 100%;
    }
    input {
      margin: 10px 0px;
    }
  }
  &.about-content {
    textarea {
      height: -webkit-fill-available;
      max-height: 300px;
    }
  }
}
button.upload-doc-btn {
  height: 160px;
  margin: 10px 0px;
  padding: 70px 86px 69px 84px;
  border-radius: 8px;
  border: dashed 1px #979797;
}
.upload-doc,
.video_link,
.upload-video,
.page-title {
  // margin: 20px 0px;
  label.form-label {
    flex-basis: auto;
  }
  .parent{
    gap:10px;
  }
}
