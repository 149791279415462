.home-sections {

  // header
  .carousel-inner {

    z-index: 4;

    .carousel-item {
      height: 100%;
    }

    .carousel-caption {
      transform: translateY(-50%);
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel-title {
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 30px;

        @media #{$maxLarge} {
          font-size: 35px;
        }

        @media #{$maxMed} {
          font-size: 27px;
        }

        @media #{$maxSmall} {
          font-size: 20px;
          margin-bottom: 20px;
        }

        @media #{$maxMobile} {
          font-size: 16px;
          margin-bottom: 15px;
        }

        @media (max-width: 450px) {
          font-size: 13px;
          margin-bottom: 15px;
        }

      }

      .carousel-desc {
        font-size: 25px;
        font-family: MontserratRegular;

        @media #{$maxLarge} {
          font-size: 20px;
        }
        
        @media #{$maxMed} {
          font-size: 17px;
        }
        
        @media #{$maxSmall} {
          font-size: 13px;
        }
        
        @media #{$maxMobile} {
          font-size: 11px;
        }

        @media (max-width: 450px) {
          font-size: 10px;
        }

      }

    }

    @media #{$maxMed} {
      max-height: 600px;
    }

    @media #{$minLarge} {
      max-height: 750px;
      height: calc(100vh - (42px + 88px));
    }


  }

  // about
  .about-section {

    padding: 5vw 0;

    @media screen and (min-width: 1920px) {
      padding: 80px 0;  
    }

    @media #{$maxSmall} {
      padding: 8vw 0;
    }
    
    h2 {
      margin-bottom: 5vw;
      font-size: 45px;
      color: $mainColor;
      font-weight: 400;

      @media screen and (min-width: 1920px) {
        margin-bottom: 55px; 
      }

    }

    .left-section {

      display: flex;
      align-items: center;

      p {
        font-size: 20px;
        line-height: 1.41;
        color: #393939;
        display: flex;
        text-align: justify;
        font-family: MontserratRegular;

        
        @media #{$maxLarge} {
          text-align: left;
          font-size: 18px;
        }
        
        @media #{$maxSmall} {
          text-align: center;
          font-size: 16px;
        }

        @media #{$maxMobile} {
          font-size: 14px;
        }

      }

    }

  }

  // how it works
  .HIW-section {

    background-color: $mainColor;
    color: #fff;
    padding: 50px 0;

    h2 {
      margin-bottom: 55px;
      font-weight: 400;
      font-size: $xl-size;
    }

    h3 {
      margin-bottom: 15px;
      font-family: 'MontserratRegular';
      font-size: $lg-size;
    }

    img {
      margin-bottom: 20px;
    }

    P {
      color: #c3c3c3;
      padding: 0 30px;
      font-size: $sm-size;
    }

    
  }

  // app section
  .app-section {

    padding: 40px 0;

    @media #{$minSmall} {
      padding: 60px 0;
    }

    @media #{$minXLarge} {
      padding: 80px 0;
    }

    .down-p {
      font-size: clamp(18px, 3.5vw, 40px);
      font-family: 'MontserratRegular';

      @media #{$minLarge} {
        margin-bottom: -100px;
        text-align: start;
      }

      @media #{$maxLarge} {
        text-align: center;
      }
      
    }

    .start-section {
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      display: flex;

      @media #{$maxLarge} {
        flex-direction: column;
        margin-top: 150px;
      }

      @media #{$maxMed} {
        margin-top: 50px;
      }
      @media #{$maxSmall} {
        margin-top: 30px;
      }

      h3 {
        font-size: $xl-size;
        @media #{$minXLarge} {
          margin-top: 50px;
        }
      }
    }

    .download-pics {
      margin-top: 20px;
      @media #{$minXLarge} {
        margin-top: 90px;
      }
      text-align: center;
    }
  }

  

}