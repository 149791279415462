$mainColor: #0033a0;
$sideColor: #ff671f;
$textColor: #2f2d2d;
$text2Color: #393939;
$text3Color: #959595;
$borderColor: #eaeaea;

$xs-size: clamp(10px, 1.5vw , 14px);
$sm-size: clamp(12px, 2vw , 16px);
$md-size: clamp(14px, 2.5vw , 18px);
$lg-size: clamp(16px, 3vw , 20px);
$xl-size: clamp(18px, 3.5vw , 26px);
$xxl-size: clamp(20px, 4vw , 30px);

:root {
  --dir-left: left;
  --dir-right: right;
}


// media queries

$minSmall: "(min-width: 576px)";

// Medium devices (tablets, 768px and up
$minMed: "(min-width: 768px)";

// Large devices (desktops, 992px and up)
$minLarge: "(min-width: 992px)";

// Extra large devices (large desktops, 1200px and up)
$minXLarge: "(min-width: 1200px)";



$maxMobile: "(max-width: 575.98px)";
$maxSmall: "(max-width: 767.98px)";

// Medium devices (tablets, less than 992px)
$maxMed: "(max-width: 991.98px)";

// Large devices (desktops, less than 1200px)
$maxLarge: "(max-width: 1199.98px)";