html:lang(ar) {
    
    body {
        font-family: GeezaRegular;
    }

    h1,h2,h3,h4 {
        font-family: GeezaRegular;
    }

    .nav-conatiner .second-nav .colII.small-nav,
    .filter-form {
        left: unset;
        right: -80%;
        &.active {
            left: unset;
            right: 0;
        }
    }

    .nav-conatiner .second-nav .dropdown-menu .dropdown-item a {
        text-align: center;
    }

    .nav-conatiner .first-nav .colI a.FAQ {
        padding-inline-end: 11px;
    }

    .admin-SB .SB-cont ul li a.active {
        background: linear-gradient(to right, #0033a0, #fff 500%, #a09eff 40%)
    }

    .fa-arrow-left, .pi-angle-right, .p-paginator-last, .p-paginator-prev, .p-paginator-first {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }


    .p-dropdown-clear-icon {
        right: 2.357rem !important;
        left: unset !important;
    }

    
    #sp-account-details .p-tabview:not(.local-gov) > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav li:first-of-type a{
        border-left: unset;
        border-right: 1px solid #0033a0;
        border-radius: 0px 5px 5px 0px;
    }

    #sp-account-details .p-tabview:not(.local-gov) > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav li:nth-of-type(4) a {        border-right: unset;
        border-left: 1px solid #0033a0;
        border-radius: 5px 0px 0px 5px;
    }

    .nav-conatiner .second-nav .colII a {
        @media #{$minLarge} {
            font-size: 15px;
        }
    }

    #sp-account-details  .p-tabview:not(.local-gov) > .p-tabview-nav-container > .p-tabview-nav-content > .p-tabview-nav li a {font-size: $xs-size; padding: .6em;}

}

