.auth-container {
    
    // height: 100vh;
    // overflow: hidden;

    & > img {
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .auth-form {
        max-width: 540px;
        background-color: #fff;
        margin: auto;
        margin-top: 80px;
        padding: 30px;
        border-radius: 6px;
        text-transform: capitalize;

        .title {
            font-size: $xxl-size;
            margin-bottom: 25px;
            font-weight: bold;
        }

        label {
            font-size: $md-size;
            margin-bottom: 15px;
            font-weight: bold;
            color: #4a4a4a;
        }

        .forget-a {
            display: inline-block;
            margin-bottom: 30px;
            margin-top: 10px;
        }
    }


    .register {
        
        .p-tabview-panels {
            display: none;
        }
        
        .auth-form {
            max-width: 600px;
            .form-controls {
                @media #{$maxSmall} {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    align-content: center;
                    a {
                        margin: 0;
                        margin-top: 10px;
                    }
                    .next-button {
                        width: 94%;
                        margin: 20px 10px 5px 10px;
                    }
                }
            }
            .PIS {
                @media #{$minLarge} {
                    padding-inline-start: 20px;
                }
            }
            .PIE {
                @media #{$minLarge} {
                    padding-inline-end: 20px;
                }
            }
            .hour-col {
                overflow: hidden;
            }
        }

        .alreadyReg {
            margin: auto;
            margin-top: 20px;
            font-weight: 700;
            color: #9a9a9a;
            width: fit-content;
            font-size: $sm-size
        }

        .tabs-container .p-tabview {
              
            ul {
              border: 0;
              justify-content: center;
              margin-top: 20px;
        
                li {
                    
                    width: 44%;
                    height: 260px;

                    @media #{$maxLarge} {
                        padding-bottom: 25px;
                    }

                    // @media #{$minSmall} {
                    //     height: 200px;
                    // }

                    // @media #{$minLarge} {
                    //     height: 260px;
                    // }

                    &:first-of-type {
                        margin-inline-end: 30px;
                    }

                    a::before, a span {
                        margin-top: -50px;
                    }

                    &:first-of-type {
                        a::before {
                            content: url('../../../assets/Icons/mi.png');
                            transform: scale(.30);
                        }

                        @media #{$minLarge} {
                            a::before {
                                transform: scale(.45);
                            }
                        }
                    }
            
                    &:nth-of-type(2) {

                        a::before {
                            content: url('../../../assets/Icons/SP.png');
                            transform: scale(.4);
                        }
                        span {
                            margin-top: -50px;
                        }

                        @media #{$minLarge} {
                            a::before {
                                transform: scale(.6);
                            }
                            span {
                                margin-top: -30px;
                            }
                        }

                    }

                    a {
                        box-shadow: 0 0 10px 0 rgba(119, 93, 255, 0.2);
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        color: $mainColor;
                        border: 2px solid $mainColor;
                        border-radius: 6px;
                        font-size: $lg-size;
                        font-weight: 600;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        margin: auto;
                        white-space: normal;
                        text-align: center;
                    }

                }

            }

        }
          
        .step_form {
            max-width: unset;
            margin-top: 40px;

            label {
                font-size: 14px;
                font-weight: normal;
            }

            .alreadyReg {
                margin: 0;
                margin-inline-start: auto;
                margin-inline-end: 20px;
                // font-size: $md-size;
            }

            .next-button {
                margin-inline-end: 15px;
            }

            select, textarea {
                color: #898989;
                border-color: rgba(166, 166, 166, 0.2);
                border-radius: 4px;
                &:focus-visible {
                    outline: 0;
                }
            }

            textarea {
                height: 100px;
            }

            .day-row {
                margin-bottom: 20px;

                .from-to {
                    & > div:first-of-type {
                        padding-inline-end: 12.5px;
                    }
                    & > div:last-of-type {
                        padding-inline-start: 12.5px;
                    }
                }
            }
            
            .main-colII {
                margin-top: 20px;
                @media #{$minLarge} {
                    margin-top: 0;
                    padding-inline-start: 40px
                }
            }

            .p-tabview {
              width: 100%;

                ul {
                  border: 0;
            
                    li {
                        min-height: 41px;
                        min-width: 43px;
                        padding: 14px 10px;
                        border-radius: 50%;
                        color: $textColor;
                        transition: all .2s ease-in-out;
                        display: flex;
                        justify-content: center;

                        &.p-tabview-selected a {
                            background-color: #fafafa;
                        }

                        a {
                            color: $mainColor;
                        }
    
                    }
                }
            }
        }
    }
}


.input-style {
    color: #898989;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px rgba(166, 166, 166, 0.2);
    &.upload {
        border: dashed 1px rgba(166, 166, 166, 0.2);
    }

    &:focus-visible {outline: 0 !important}
    // text-transform: capitalize;
}

.tag_style {
    padding: 5px 10px;
    border-radius: 16px;
    background-color: $mainColor;
    color: #fff;
    display: inline-block;
    margin: 4px;
    font-size: 12px;
}